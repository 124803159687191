@import './action-announcement.default.scss';

@keyframes PushInDown {
	from {
		height: 0;
	}

	to {
		height: 50px;
	}
}

@keyframes PushOutUp {
	from {
		height: 50px;
	}

	to {
		height: 0;
	}
}

.content {
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	animation: PushInDown 0.3s ease-in-out forwards;
	background-color: $actionAnnouncementContentBackgroundColor;
	position: relative;
	z-index: 3;

	&.error {
		background-color: $actionAnnouncementContentErrorBackgroundColor;
	}

	&.warning {
		background-color: $actionAnnouncementContentWarningBackgroundColor;
	}

	&.pushOut {
		animation: PushOutUp 0.3s ease-in-out forwards;
	}

	.childrenWrapper {
		flex-grow: 1;
		text-align: center;
	}

	.closeIconWrapper {
		padding-right: 20px;

		.closeIcon {
			font-size: 20px;
			&:hover {
				background-color: transparent;
			}
		}
	}
}
