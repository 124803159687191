@import 'src/styles/theme';

$tableWrapperBGColor: var(--tableWrapperBGColor, white);
$tableBGColor: var(--tableBGColor, white);
$tableHeaderTextColor: var(--tableHeaderTextColor, #525f7f);
$tableHeaderBGColor: var(--tableHeaderBGColor, white);
$tableHeaderColorDisabled: var(--tableHeaderColorDisabled, #d0d5dd);
$tableBorderColor: var(--tableBorderColor, rgba(0, 0, 0, 0.125));
$tableBodyRowColor: var(--tableBodyRowColor, #f9f9f9);
$tableBodyTextColor: var(--tableBodyTextColor, #636363);
$tableBodyTextFontWeight: var(--tableBodyTextFontWeight, 400);
$tableInputsColor: var(--tableInputsColor, #979797);
$tableBodyRowSelectedColor: var(--tableBodyRowSelectedColor, #e2e2ff80);
$tableBorderRaduis: var(--tableBorderRaduis, 8px);
$tableFontSize: var(--tableFontSize, 12.5px);
$tableThFontSize: var(--tableThFontSize, 12px);
$tableHeaderPadding: var(--tableHeaderPadding, 12px 11px);
$tablePadding: var(--tablePadding, 11px 12px);
$tableTableResponsiveMaxHeight: var(--tableTableResponsiveMaxHeight, 650px);
$tableTHeadFromTop: var(--tableTHeadFromTop, 0);
$tableThFirstChildPadding: var(--tableThFirstChildPadding, 0 11px 0 11px);
$tableSummaryFromTop: var(--tableSummaryFromTop, 40px);
$tableSummaryOverallFontColor: var(--tableSummaryOverallFontColor, red);
$tableTdFirstChildPadding: var(--tableTdFirstChildPadding, 11px 0px 11px 11px);
$tableTableWrapperBorder: var(--tableTableWrapperBorder, 1px solid $tableBorderColor);
$tableBarLoaderFromTop: var(--tableBarLoaderFromTop, 39px);
$tableBarLoaderFromLeft: var(--tableBarLoaderFromLeft, 0px);
$tableBarLoaderHeight: var(--tableBarLoaderHeight, 2px);
$tableTableThBorderRight: var(--tableTableThBorderRight, 1px solid $tableBorderColor);
$tableTableThBorderBottom: var(--tableTableThBorderBottom, 1px solid #bcbcbc);
$tableTableThFontWeight: var(--tableTableThFontWeight, 600);
$tableTableThFromTop: var(--tableTableThFromTop, 0px);
$tableTableThBackgroundColor: var(--tableTableThBackgroundColor, #fff);
$tableTableThIconFromLeft: var(--tableTableThIconFromLeft, 2px);
$tableTableThIconFromBottom: var(--tableTableThIconFromBottom, 1px);
$tableTableTBodyPadding: var(--tableTableTBodyPadding, 20px);
$tableSubRowTdBorderTop: var(--tableSubRowTdBorderTop, 1px solid $tableBorderColor);
$tableSubRowTdLastChildBorderBottom: var(--tableSubRowTdLastChildBorderBottom, 1px solid $tableBorderColor);
$tableTableTrBg: var(--tableTableTrBg, $backgroundColor);
$tableTableTrHeight: var(--tableTableTrHeight, 47px);
$tableTableTdBorderRight: var(--tableTableTdBorderRight, 1px solid $tableBorderColor);
$tableTableTdBorderBottom: var(--tableTableTdBorderBottom, 1px solid $tableBorderColor);
$tableNoRowsBackgroundColor: var(--tableNoRowsBackgroundColor, #adaeff);
$tableNoRowsColor: var(--tableNoRowsColor, white);
$tableNoRowsBorderRadius: var(--tableNoRowsBorderRadius, 8px);
$tableBelowTablePadding: var(--tableBelowTablePadding, 20px 5px 20px 10px);
$tableArrowDirectionColor: var(--tableArrowDirectionColor, #7f56d9);
$tableArrowDirectionFontSize: var(--tableArrowDirectionFontSize, 17px);
