@import './sidebar.default.scss';

.wrapper {
	padding: $controlledSidebarWrapperPadding;

	.logoWrapper {
		height: $controlledSidebarTogglerHeight;
		padding: $controlledSidebarTogglerPadding;
	}

	.fullList {
		.itemWrapper {
			.itemTitle {
				margin: $controlledSidebarFullListItemTitleMargin;
				white-space: nowrap;
			}
		}
	}

	.hamburgerSidebar {
		position: absolute;
		bottom: 70px;
		left: 17px;
		color: #6b6b6b;
		transition: 200ms;

		&.openSidebar {
			transform: rotate(180deg);
			left: 215px;
		}
	}

	.list {
		margin: $controlledListMargin;

		.itemWrapper {
			width: $controlledListItemWrapperWidth;
			height: $controlledListItemWrapperHeight;
			line-height: $controlledListItemWrapperLineHeight;
			font-size: $controlledListItemWrapperFontSize;
			font-weight: 500;
			padding: $controlledListItemWrapperPadding;
			cursor: pointer;
			margin: $controlledListItemWrapperMargin;
			display: flex;
			align-items: center;
			// border-radius: 0px;

			&:hover {
				background-color: $controlledListItemWrapperHoverBackgroundColor;
			}

			&.activeItem {
				background-color: $controlledListItemWrapperActiveItemBackgroundColor;
				// border-radius: 0px;

				&:hover {
					background-color: $controlledListItemWrapperActiveItemHoverBackgroundColor;
				}

				.itemLink {
					color: $primary700;
				}
			}

			.itemLink {
				height: 100%;
				width: 100%;

				.item {
					display: flex;
					align-items: center;
					height: 100%;
					width: 100%;
				}
			}
		}
	}
}

.miniLogoWrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	.miniLogo {
		position: relative;
		width: 50px;
		height: 18px;
		object-fit: contain;
	}
}
