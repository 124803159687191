@import 'src/styles/theme';

$columnPickerColumnPickerMenuPadding: var(--columnPickerColumnPickerMenuPadding, 8px); 
$columnPickerSubtitleFontSize: var(--columnPickerSubtitleFontSize, 12px); 
$columnPickerSubtitleMargin: var(--columnPickerSubtitleMargin, 0 0 15px 0); 
$columnPickerListMargin: var(--columnPickerListMargin, 0 0 5px 0); 
$columnPickerItemLineHeight: var(--columnPickerItemLineHeight, 30px); 
$columnPickerItemPadding: var(--columnPickerItemPadding, 0 0 0 10px);
$columnPickerBackgroundColorHover: var(--columnPickerBackgroundColorHover, #e7e7e7);
$columnPickerContentGap: var(--columnPickerContentGap, 5px);
$columnPickerCheckboxWidth: var(--columnPickerCheckboxWidth, 15px); 
$columnPickerCheckboxHeight: var(--columnPickerCheckboxHeight, 15px); 
$columnPickerCheckboxMargin: var(--columnPickerCheckboxMargin, 0 10px 0 0); 
$columnPickerCheckboxAccentColor: var(--columnPickerCheckboxAccentColor, $primaryColor); 
$columnPickerTitleFontSize: var(--columnPickerTitleFontSize, 13px); 
$columnPickerTitleFontWeight: var(--columnPickerTitleFontWeight, 500); 
$columnPickerActionsGap: var(--columnPickerActionsGap, 10px); 
$columnPickerActionsPadding: var(--columnPickerActionsPadding, 0 10px 0 0); 
$columnPickerActionsMarginTop: var(--columnPickerActionsMarginTop, 10px 0 0 0); 
$columnPickerItemBorderRadius: var(--columnPickerItemBorderRadius, 4px); 
$columnPickerActionsBtnBackgroundColorHover: var(--columnPickerActionsBtnBackgroundColorHover, #e7e7e7)